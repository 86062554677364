import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'
import Grid from 'components/flex/Posts/Layout/Grid'

// Interface
import { CasesProps } from 'components/flex/Cases/CasesShell'

const Cases: React.FC<CasesProps> = ({ fields }) => {
  const {
    allWpCase,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.caseQueryQuery>(graphql`
    query caseQuery {
      allWpCase(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalCaseFragment
          }
        }
      }
    }
  `)

  const cases: unknown = allWpCase.edges

  return (
    <section>
      <BlogBink posts={cases as BlogBinkPosts} id="cases" limit={Infinity}>
        <div className="py-5">
          <Grid
            // @ts-ignore
            fields={{
              loadmoretext: 'Laad meer',
              readmoretext: 'Lees meer',
            }}
          />
        </div>
      </BlogBink>
    </section>
  )
}

export default Cases
